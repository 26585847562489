import Splide from "@splidejs/splide";

let canvasWidth = 0;
const screenWidth = window.innerWidth;
const magnification = 2.33;

// スライダーの設定
const fvSlider = new Splide(".fv__slider", {
  type: "fade",
  rewind: true,
  interval: 6000,
  speed: 3000,
  autoplay: true,
  arrows: false,
  pauseOnHover: false,
  pagination: false,
});

const pagination = document.querySelector(".fv__pagination");

// スライドの変化を表現する円
const transitionState = (rate, targetId, canvasSize) => {
  const angle = Math.ceil(rate * 100 * 3.6); //splideから取得した変化の割合を100倍する
  const canvas = document.getElementById(targetId);
  const context = canvas.getContext("2d");

  canvas.width = canvasSize;
  canvas.height = canvasSize;

  // 回転
  context.translate(canvas.width / 2, canvas.height / 2);
  context.rotate((270 * Math.PI) / 180);
  context.translate(-canvas.width / 2, -canvas.height / 2);

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.beginPath();

  context.arc(
    canvas.width / 2,
    canvas.width / 2,
    (canvas.width / 2) - 1,
    (angle * Math.PI) / 180,
    (0 * Math.PI) / 180,
    true
  );

  context.strokeStyle = "#fff";
  context.lineWidth = 2;
  context.stroke();
};

fvSlider.on("mounted", () => {
  // スライダードットを生成
  for (let i = 0; i < fvSlider.length; i++) {
    let sliderDot = document.createElement("li");
    pagination.appendChild(sliderDot);

    // canvasWidth に li の2.33倍をかけた値を返す
    canvasWidth = Math.ceil(sliderDot.clientWidth * magnification);
  }

  // スライダードットのクリックイベント
  pagination.childNodes.forEach((elm, index) => {
    elm.addEventListener("click", (e) => {
      fvSlider.go(index);
    });
  });
});

fvSlider.on("active", (e) => {
  paginationControl(e.index);
});

// スライダーを実行
fvSlider.mount();

function paginationControl(pageIndex) {
  const paginationChild = pagination.children;
  const paginationActive = paginationChild[pageIndex];
  const canvasElement = "<canvas id='canvas' class='fv__canvas'></canvas>";

  [...paginationChild].forEach((e, index) => {
    if (paginationChild[index].firstChild) {
      paginationChild[index].firstChild.remove();
    }
  });

  paginationActive.insertAdjacentHTML("afterbegin", canvasElement);

  fvSlider.on("autoplay:playing", function (rate) {
    transitionState(rate, "canvas", canvasWidth);
  });
}
